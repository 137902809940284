export default {
    Home: 'Головна',
    Search: 'Пошук',
    Calendar: 'Календар',
    Profile: 'Профайл',
    Name: 'Ім\'я',
    'Full name': 'ПІБ',
    Title: 'Назва',
    'Short title': 'Скорочена назва',
    'Last name': 'Прізвище',
    'First name': 'Им\'я',
    'Middle name': 'По-батькові',
    Email: 'Email',
    Phone: 'Телефон',
    Save: 'Зберегти',
    Add: 'Додати',
    Create: 'Створити',
    Update: 'Оновити',
    Edit: 'Редагувати',
    Delete: 'Видалити',
    View: 'Перегляд',
    Cancel: 'Скасувати',
    Select: 'Вибрати',
    Location: 'Місце',
    Locations: 'Місця',
    'Add location': 'Додати місце',
    Change: 'Правка',
    Details: 'Деталі',
    Slug: 'Псевдонім',
    Label: 'Мітка',
    Begin: 'Початок',
    End: 'Кінець',
    Description: 'Опис',
    Summary: 'Анотація',
    Day: 'День',
    Month: 'Місяць',
    Year: 'Рік',
    'Personal data': 'Особисті дані',
    Birthday: 'Дата народження',
    Age: 'Вік',
    Attach: 'Приєднати',
    Detach: 'Від\'єднати',
    'Attach to': 'Приєднати до',
    'Detach from': 'Від\'єднати від',
    'Start typing to Search': 'Введіть для пошуку',
    All: 'Все',
    hour: 'година',
    hours: 'годин',
    Hour: 'Година',
    Hours: 'Годин',
    Language: 'Мова',
    en: 'Англійська',
    ru: 'Російська',
    uk: 'Українська',
    Section: 'Розділ',
    Source: 'Джерело',
    Resource: 'Ресурс',
    Theme: 'Тема',
    Annotation: 'Анотація',
    Media: 'Медіа',
    Materials: 'Матеріали',
    Document: 'Документ',
    Documents: 'Документи',
    File: 'Файл',
    Files: 'Файли',
    'File type': 'Тип файлу',
    'File types': 'Типи файлів',
    'Select file': 'Вибрати файл',
    'Select files': 'Вибрати файли',
    'Upload file': 'Завантажити файл',
    'Upload files': 'Завантажити файли',
    'Download file': 'Вибрати файл',
    'Download files': 'Вибрати файли',
    Image: 'Зображення',
    Images: 'Зображення',
    Audio: 'Аудіо',
    'Preview not available': 'Перегляд не доступний',
    Download: 'Завантажити',
    Upload: 'Завантажити',
    Open: 'Відкрити',
    Link: 'Посилання',
    Folder: 'Папка',
    'Create folder': 'Створити папку',
    'Add folder': 'Додати папку',
    'New folder': 'Нова папка',
    Rename: 'Перейменувати',
    'The given data was invalid.': 'Помилка вводу даних.',
    'Moved to trash': 'Переміщено у кошик',
    'Restored successful': 'Успішно відновлено',
    'Uploaded successful': 'Успішно завантажено',
    'Created successful': 'Успішно створено',
    'Updated successful': 'Успішно оновлено',
    'Deleted successful': 'Успішно видалено',
    'Cannot delete': 'Неможливо видалити',
    Loading: 'Завантаження',
    'Load more': 'Завантажити ще',
    'Loading was finished': 'Завантаження завершено',
    Done: 'Готово',
    'Already exist': 'Вже існує',
    'Not found': 'Не знайдено',
    Close: 'Закрити',
    Trash: 'Кошик',
    'No data to display': 'Немає даних для відображення',
    Restore: 'Відновити',
    'Set up': 'Встановити',
    'Clean up': 'Очистити',
    'Clean up trash': 'Очистити кошик',
    Size: 'Розмір',
    Author: 'Автор',
    Owner: 'Власник',
    'Created at': 'Створено',
    'Updated at': 'Оновлено',
    Exclude: 'Виключити',
    'Available in': 'Доступно у',
    'Available to all': 'Доступно всім',
    Move: 'Перемістити',
    'Move to': 'Перемістити в',
    'Move here': 'Перемістити сюди',
    Copy: 'Копіювати',
    'Copy to': 'Копіювати в',
    'Copy here': 'Копіювати сюди',
    True: 'Правда',
    true: 'Правда',
    False: 'Неправда',
    false: 'Неправда',
    Preview: 'Прев\'ю',
    'Without limit': 'Без обмеження',
    Percents: 'Проценти',
    Duration: 'Тривалість',
    minutes: 'хвилин',
    min: 'хв',
    Start: 'Старт',
    Skip: 'Пропустити',
    Correct: 'Правильно',
    Incorrect: 'Неправильно',
    Date: 'Дата',
    Check: 'Тест',
    from: 'з',
    to: 'по',
    'To schedule': 'Запланувати',
    Scheduled: 'Заплановано',
    'Scheduled for': 'Заплановано на',
    'Time out': 'Час вийшов',
    Active: 'Активний',
    Status: 'Статус',
    Progress: 'Прогрес',
    Skipped: 'Пропущено',
    History: 'Історія',
    'Sign in': 'Увійти',
    'Sign out': 'Вийти',
    'Forgot password?': 'Забули пароль?',
    'Change password': 'Змінити пароль',
    Password: 'Пароль',
    Login: 'Логін',
    'Old password': 'Старий пароль',
    'New password': 'Новий пароль',
    'Password confirmation': 'Підтвердження пароля',
    User: 'Користувач',
    'Back to sign in': 'Повернутись на сторінку входу',
    'Copy to clipboard': 'Скопіювати в буфер обміну',
    'Copied to clipboard': 'Скопійовано в буфер обміну',
    'Meeting url': 'Посилання на конференцію',
    Invite: 'Запрошення',
    'Invite sent': 'Запрошення надіслано',
    Send: 'Надіслати',
    Library: 'Бібліотека',
    Hello: 'Вітаємо',
    Hi: 'Привіт',
    'Expand all': 'Розгорнути всі',
    'Collapse all': 'Згорнути всі',
    Config: 'Конфігурація',
    Settings: 'Налаштування',
    Options: 'Опції',
    Clone: 'Клонувати',
    Type: 'Тип',
    Widget: 'Віджет',
    Value: 'Значення',
    Color: 'Колір',
    'Default value': 'Значення за змовчуванням',
    Continue: 'Продовжити',
    Logout: 'Вийти',
    confirmation: {
        delete: 'Ви впевнені, що хочете видалити <br> <b><em>{entity}</em></b>? <br> <span class="error--text">Цю дію не можна буде відмінити в майбутньому!</span>'
    },
    validation: {
        required_field: 'Поле обов\'язкове для заповнення',
        email: 'Поле повинно бути дійсною email адресою',
        password: 'Поле повнно мати довжину не менше 8-ми символів, одну велику літеру, число та спецсимвол',
        password_confirm: 'Паролі не співпадають',
    }
}
