<template>
    <v-row
        class="w-inline-field-group"
    >
        <v-col
            cols="5"
            class="pr-2"
        >
            <label :for="field.name" class="caption text-uppercase">{{ field.props.label }}</label>
            <div class="caption text--secondary">{{ field.props.hint }}</div>
        </v-col>
        <v-col
            cols="7"
            class="d-flex justify-end"
        >
            <slot></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'WInlineFieldGroup',
    props: {
        field: Object
    }
}
</script>

<style lang=scss>
.w-inline-field-group {
    .v-input--switch {
        margin-top: 0;
    }

    .v-text-field {
        margin-top: 0;
    }

    .v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none;
    }
}
</style>
