import Vue from 'vue'
import VueRouter from 'vue-router'
import appsRoutes from '@/tools/bootstrap/appsRoutes'
import AuthProvider from '@/tools/AuthProvider'

Vue.use(VueRouter)

const routes = [
    ...appsRoutes,
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {}
    },
    {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('@/views/auth/LoginView.vue'),
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/errors/Error404View'),
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '*',
        redirect: 'error-404'
    }
]

const createRouter = () => {
    const routerInstance = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior() {
            return {
                x: 0,
                y: 0
            }
        }
    })

    routerInstance.beforeEach(AuthProvider.routeGuard)

    return routerInstance
}

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
