import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import auth from './auth'

import appsStores from '@/tools/bootstrap/appsStores'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        ...appsStores,
        auth,
        app
    },
    getters
})
