// Register apps services
// https://webpack.js.org/guides/dependency-management/#requirecontext

const modulesFiles = require.context('@/app', true, /localisation.js$/)

const localisations = modulesFiles.keys().reduce((modules, path) => {
    const moduleName = path.replace(/^\.\/(.*)(\/localisation)\.\w+$/, '$1')
    const value = modulesFiles(path)
    modules[moduleName] = value.default

    return modules
}, {})

export default lang => {
    const result = {}

    for (const appName in localisations) {
        if(localisations[appName][lang]) {
            result[appName] = localisations[appName][lang]
        }
    }

    return result
}
