import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import VuetifyConfirm from './confirm'
import appConfig from '@/config/app.config'

Vue.use(Vuetify)

const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: appConfig.theme.colorScheme,
            dark: appConfig.theme.colorScheme
        }
    }
})

Vue.use(VuetifyConfirm, { vuetify })

export default vuetify
