export default [
    {
        path: '/sites/storage/:siteId',
        name: 'sites.storage',
        props: true,
        component: () => import('./views/SiteStorage')
    },
    {
        path: '/sites/storage/:siteId/using',
        name: 'sites.storage.using',
        props: true,
        component: () => import('./views/UsingFilesView')
    },
    {
        path: '/sites/storage/:siteId/trash',
        name: 'sites.storage.trash',
        props: true,
        component: () => import('./views/TrashFilesView')
    },
    {
        path: '/sites/node-types/:siteId',
        name: 'sites.nodeTypes',
        props: true,
        component: () => import('./views/NodeTypesTreeView')
    },
    {
        path: '/sites/archive-content/:siteId',
        name: 'sites.archiveContent',
        props: true,
        component: () => import('./views/ArchiveContentView')
    }
]
