/**
 * @param {Object} options
 * @returns {{perPage, orderBy, page, direction: (string)}}
 */
export const vDataTableOptionsAdapter = function (options) {
    return {
        page: options.page,
        perPage: options.itemsPerPage,
        sortBy: options.sortBy[0],
        sortDirection: options.sortDesc[0] ? 'desc' : 'asc'
    }
}

export const wDataTableImplementIndex = function (items) {

}
