const app = {
    name: 'PDMU',
    logo: require('@/assets/logo.svg'),
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
}

const colorSchemes = {
    materio: {
        navbarColor: '#1f1d31',
        navbarTextColor: '#e7e3fcad',
        appBarHeight: 56,
        appBodyColor: '#f4f5fa',
        appBarColor: '#f4f5fa',
        colors: {
            primary: '#2855af',
            accent: '#0d6efd',
            secondary: '#8A8D93',
            success: '#56CA00',
            info: '#16B1FF',
            warning: '#FFB400',
            error: '#FF4C51'
        }
    },
    vuexy: {
        navbarColor: '#1f1d31',
        navbarTextColor: '#e7e3fcad',
        appBarHeight: 52,
        appBodyColor: '#f4f5fa',
        appBarColor: '#f4f5fa',
        colors: {
            primary: '#7367f0',
            accent: '#00cfe8',
            secondary: '#82868b',
            success: '#28c76f',
            info: '#16B1FF',
            warning: '#ff9f43',
            error: '#ea5455'
        }
    },
    gastro: {
        navbarColor: '#1f1d31',
        navbarTextColor: '#e7e3fcad',
        appBarHeight: 52,
        appBodyColor: '#f4f5fa',
        appBarColor: '#f4f5fa',
        colors: {
            primary: '#685889',
            accent: '#04a2ce',
            secondary: '#82868b',
            success: '#04a257',
            info: '#3597de',
            warning: '#ffa13c',
            error: '#e25a5e'
        }
    }
}

const theme = {
    colorScheme: colorSchemes.materio.colors,
    navbarColor: colorSchemes.materio.navbarColor,
    navbarTextColor: colorSchemes.materio.navbarTextColor,
    appBarHeight: colorSchemes.materio.appBarHeight,
    appBodyColor: colorSchemes.materio.appBodyColor,
    appBarColor: colorSchemes.materio.appBarColor
}

export default {
    app,
    theme,
    colorSchemes
}
