export default {
    tokenKey: 'pdmubo.tid',
    refreshTokenKey: 'pdmubo.rtid',
    userUri: 'auth/user',
    loginUri: 'auth/login',
    logoutUri: 'auth/logout',
    refreshTokenUri: 'auth/refresh-token',
    forgotPasswordUri: 'auth/forgot-password',
    resetPasswordUri: 'auth/reset-password',
}
