<template>
    <div
        v-if="!hideIfEmptyComputed"
        class="d-flex align-center"
    >
        <div v-if="inline" class="d-flex align-center">
            <template v-for="(item, i) in items">
                <v-divider
                    v-if="item.divider"
                    :key="`divider-${i}`"
                    vertical
                    class="mx-1"
                />
                <v-tooltip
                    v-else-if="item.text"
                    :key="item.text"
                    :disabled="!inlineIcon"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            :text="!inlineIcon"
                            :icon="inlineIcon"
                            :small="dense"
                            :disabled="item.disabled"
                            :to="item.to"
                            :href="item.href"
                            :target="item.target"
                            v-on="{ ...on, ...itemOnResolver(item.on) }"
                            :class="i > 0 ? 'ml-1' : ''"
                        >
                            <w-icon
                                v-if="item.icon"
                                :left="!inlineIcon && !!item.text"
                                :color="item.color"
                                :small="dense"
                                :value="item.icon"
                            />
                            <span v-if="!inlineIcon && item.text">{{ item.text }}</span>
                        </v-btn>
                    </template>
                    <span>{{ item.text }}</span>
                </v-tooltip>
            </template>
        </div>
        <v-menu v-else offset-y>
            <template v-slot:activator="{ on }">
                <slot
                    name="activator"
                    :on="on"
                >
                    <v-btn
                        :icon="!text"
                        :text="!!text"
                        v-bind="activator"
                        v-on="items.length > 0 ? on : undefined"
                    >
                        <span v-if="text">{{ text }}</span>
                        <w-icon :right="text !== undefined" :value="icon" />
                    </v-btn>
                </slot>
            </template>
            <v-list :dense="dense" min-width="150">
                <template v-for="(item, i) in items">
                    <v-divider
                        v-if="item.divider"
                        :key="`divider-${i}`"
                    />
                    <v-subheader
                        v-else-if="item.subheader"
                        :key="`subheader-${i}`"
                    >
                        {{ item.subheader }}
                    </v-subheader>
                    <v-list-item
                        v-else
                        :color="activeColor"
                        :key="item.text"
                        :disabled="item.disabled"
                        :dense="dense"
                        :to="item.to"
                        :href="item.href"
                        :target="item.target"
                        v-on="item.on"
                    >
                        <w-icon
                            v-if="item.icon"
                            :value="item.icon"
                            :small="dense"
                            :color="item.color"
                            :disabled="item.disabled"
                            class="mr-3"
                        />
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            <v-list-item-subtitle
                                v-if="item.subtitle"
                                :disabled="item.disabled"
                            >{{ item.subtitle }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'WActionsMenu',
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        inlineIcon: {
            type: Boolean,
            default: false
        },
        activator: {
            type: Object,
            default: function () {
                return {}
            }
        },
        icon: {
            type: String,
            default: 'mdi-dots-vertical'
        },
        text: {
            type: String,
            default: undefined
        },
        activeColor: {
            type: String,
            default: 'primary'
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        hideIfEmpty: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hideIfEmptyComputed() {
            return this.hideIfEmpty && this.items.length === 0
        }
    },
    methods: {
        itemOnResolver(on) {
            if(!on) return undefined

            const { click } = on

            if(click) {
                const clickResolver = (e) => {
                    e.stopPropagation()
                    click()
                }

                return {
                    ...on,
                    click: clickResolver
                }
            }

            return on
        },
        onClickActionsMenu(item) {
            this.$emit('click', item)
        }
    }
}
</script>

<style lang=scss>

</style>
