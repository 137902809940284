import Vue from 'vue'

import WIcon from './components/WIcon'
import WIconNext from './components/WIcon/WIconNext'
import WDataTable from './components/WDataTable'
import WEntryPreview from './components/WEntryPreview'
import WBtnAction from './components/WButtons/WBtnAction'
import WBtnAdd from './components/WButtons/WBtnAdd'
import WBtnUpdate from './components/WButtons/WBtnUpdate'
import WActionsMenu from './components/WActionsMenu'
import WToolbarMenu from './components/WToolbarMenu'
import WAsideDrawer from './components/WAsideDrawer'
import WOptionsList from './components/WOptionsList/WOptionsList'
import WOptionsListItem from './components/WOptionsList/WOptionsListItem'
import WSimpleForm from './components/WSimpleForm'
import WActionsDrawer from './components/WActionsDrawer'
import WBirthdayInput from './components/WBirthdayInput'
import WFieldsGroup from './components/WFieldsGroup'
import WGenderInput from './components/WGenderInput'
import WDialogDrawer from './components/WDialogDrawer'
import WFxTable from './components/WFxTable'
import WDateInput from './components/WDateInput'
import WBooleanMarker from './components/WBooleanMarker'
import WSearchField from './components/WSearchField'

const WidgetsPlugin = {
    install(Vue) {
        Vue.component('WIcon', WIcon)
        Vue.component('WIconNext', WIconNext)
        Vue.component('WDataTable', WDataTable)
        Vue.component('WEntryPreview', WEntryPreview)
        Vue.component('WBtnAction', WBtnAction)
        Vue.component('WBtnAdd', WBtnAdd)
        Vue.component('WBtnUpdate', WBtnUpdate)
        Vue.component('WActionsMenu', WActionsMenu)
        Vue.component('WToolbarMenu', WToolbarMenu)
        Vue.component('WAsideDrawer', WAsideDrawer)
        Vue.component('WOptionsList', WOptionsList)
        Vue.component('WOptionsListItem', WOptionsListItem)
        Vue.component('WSimpleForm', WSimpleForm)
        Vue.component('WActionsDrawer', WActionsDrawer)
        Vue.component('WBirthdayInput', WBirthdayInput)
        Vue.component('WFieldsGroup', WFieldsGroup)
        Vue.component('WGenderInput', WGenderInput)
        Vue.component('WDialogDrawer', WDialogDrawer)
        Vue.component('WFxTable', WFxTable)
        Vue.component('WDateInput', WDateInput)
        Vue.component('WBooleanMarker', WBooleanMarker)
        Vue.component('WSearchField', WSearchField)
    }
}

Vue.use(WidgetsPlugin)
