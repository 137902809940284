import _ from 'lodash'
import store from '@/store'

export default (key, number) => {
    if (typeof key !== 'string') {
        throw new Error(key + ' must be string. ' + typeof key + ' given')
    }

    const pattern = _.get(store.state.app.localisation, `pluralization.${key}`, key)

    if(pattern === key) {
        return key
    }

    const variants = pattern.split('|')
    const card = []
    const maths = []

    variants.forEach(item => {
        const regex = /\[(.*)\]/
        maths.push(item.match(regex)[1].split(','))
        card.push(item.replace(regex, '').trim())
    })

    const comparator = function (number, a, b) {
        if(typeof Number(number) !== 'number') {
            console.warn(`${number} can be number, ${typeof number} given.`)
            return false
        }

        if(typeof Number(a) !== 'number') {
            console.warn(`${a} can be number, ${typeof a} given.`)
            return false
        }

        if(!b) {
            return Number(number) === Number(a)
        }

        if(b === '*') {
            return Number(number) >= Number(a)
        }

        if(typeof Number(b) !== 'number') {
            console.warn(`${b} can be number, ${typeof b} given.`)
            return false
        }

        return Number(number) >= Number(a) && Number(number) <= Number(b)
    }

    let result = card[0]

    for (let i = 0; i < maths.length; i++) {
        const compares = maths[i]

        if(compares.length === 1 && comparator(number, compares[0])) {
            result = card[i]
            break
        }

        if(compares.length === 2 && comparator(number, compares[0], compares[1])) {
            result = card[i]
            break
        }
    }

    return result
}
