import _ from 'lodash'
import Vue from 'vue'
import store from '@/store'

export default class Notification {
    static TYPE = {
        SUCCESS: 'success',
        ERROR: 'error',
        INFO: 'info',
        WARNING: 'warning',
        DEFAULT: 'default'
    }

    static TARGET = {
        ALERT: 'alert',
        NOTIFY: 'notify',
        SNACKBAR: 'snackbar'
    }

    static isDisabled() {
        return store.getters['notification/isDisabled']
    }

    static isEnabled() {
        return !Notification.isDisabled()
    }

    static disabled() {
        store.dispatch('notification/disabled', true)
    }

    static enabled() {
        store.dispatch('notification/disabled', false)
    }

    static make(message, options) {
        return new Notification(message, options)
    }

    constructor(message, options) {
        this.message = undefined
        this.target = Notification.TARGET.ALERT
        this.type = Notification.TYPE.DEFAULT
        this.position = 'top'
        this.timeout = 5000
        this.onClose = undefined

        if(options) this.setOptions(options)
        if(message) this.setMessage(message)
    }

    setMessage(message) {
        if(!_.isString(message)) {
            this.setOptions(message)
        } else {
            this.message = message
        }
    }

    setOptions(options) {
        for (const optionsKey in options) {
            if(optionsKey === 'message') {
                this.message = options[optionsKey]
            } else if(_.has(this, optionsKey)) {
                this[optionsKey] = options[optionsKey]
            }
        }
    }

    setType(type) {
        this.type = type

        return this
    }

    open() {
        this[this.target]()
    }

    alert() {
        Vue.$toast.open({
            message: this.message,
            type: this.type,
            position: this.position,
            duration: this.timeout
        })
    }

    notify() {
        const icons = {
            [Notification.TYPE.DEFAULT]: 'mdi-information-outline',
            [Notification.TYPE.SUCCESS]: 'mdi-check-circle',
            [Notification.TYPE.INFO]: 'mdi-information-outline',
            [Notification.TYPE.WARNING]: 'mdi-alert',
            [Notification.TYPE.ERROR]: 'mdi-alert-octagon'
        }

        const type = this.type === Notification.TYPE.DEFAULT
            ? Notification.TYPE.INFO
            : this.type

        store.dispatch('notification/openNotify', {
            message: this.message,
            type: type,
            icon: icons[this.type],
            onClose: this.onClose
        })
    }

    snackbar() {
        store.dispatch('notification/openSnackbar', {
            message: this.message,
            color: this.type,
            timeout: this.timeout,
            onClose: this.onClose,
        })
    }

    static message(mess, cb) {
        const notify = new Notification(mess, {
            onClose: cb
        })

        notify.snackbar()
    }

    static success(mess, cb) {
        const notify = new Notification(mess, {
            type: Notification.TYPE.SUCCESS,
            onClose: cb
        })

        notify.snackbar()
    }

    static error(mess, cb) {
        const notify = new Notification(mess, {
            type: Notification.TYPE.ERROR,
            onClose: cb
        })

        notify.snackbar()
    }

    static warning(mess, cb) {
        const notify = new Notification(mess, {
            type: Notification.TYPE.WARNING,
            onClose: cb
        })

        notify.snackbar()
    }
}
