/* eslint-disable */
import request from '@/tools/request'

const prefix = 'app'

export function getLocales(lang) {
    return request({
        url: `${prefix}/locales`,
        method: 'get',
        params: { lang }
    })
}
