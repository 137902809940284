import _ from 'lodash'
import axios from 'axios'
import Notification from '@/plugins/notification/Notification'
import Debugger from '@/tools/Debugger'
import router from '@/router'
import AuthProvider from '@/tools/AuthProvider'
import { authRefreshToken } from '@/api/auth'
import jwtDecode from 'jwt-decode'
import authConfig from '@/config/auth.config'
import localisationResolver from '@/plugins/Trans/localisationResolver'

const baseURL = process.env.VUE_APP_BASE_API
const refreshTokenUri = authConfig.refreshTokenUri

const request = axios.create({
    baseURL
    // timeout: 10000 // request timeout
})

request.interceptors.request.use(async request => {
    let accessToken = AuthProvider.getToken()
    // let isExpired = false
    //
    // if(accessToken) {
    //     const payload = jwtDecode(accessToken)
    //     isExpired = Date.now() >= payload.exp * 1000
    // }
    //
    // if (isExpired) {
    //     const refreshRes = await axios.post(
    //         `${baseURL}/${refreshTokenUri}`,
    //         {
    //             refreshToken: AuthProvider.getRefreshToken(),
    //             accessToken: AuthProvider.getToken()
    //         })
    //
    //     accessToken = refreshRes.data.accessToken
    //
    //     AuthProvider.setToken(accessToken)
    //     AuthProvider.setRefreshToken(refreshRes.data.refreshToken)
    // }

    if(accessToken) { //Authorization: Bearer <token>
        request.headers['x-access-token'] = accessToken
        request.headers['Authorization'] = 'Bearer ' + accessToken
    }

    request.headers['x-accept-locale'] = null

    return request
}, error => Promise.reject(error))

// response interceptor
request.interceptors.response.use(response => {
    const res = response

    // Debugger.log('plugins/api.success: ', res)

    // Set message response if exist
    if (Notification.isEnabled() && _.has(res, 'data.message')) {
        const message = localisationResolver(_.get(res, 'data.message'))

        Notification.success(message)
    }

    if (Notification.isDisabled()) {
        Notification.enabled()
    }

    return response
}, async (error) => {
    const res = error.response

    Debugger.log('plugins/api.error: ', error.response) // For debug

    const status = Number(_.get(res, 'status', 500))

    let message

    if (_.has(res, 'data.error.message')) {
        message = _.get(res, 'data.error.code') || res.data.error.message
        message = localisationResolver(message)
    } else if (_.has(res, 'statusText')) {
        message = res.statusText
    }

    if (status === 401) {
        Notification.disabled()
    }

    if (Notification.isEnabled() && message) {
        Notification.error(message)
    }

    if(Notification.isDisabled()) {
        Notification.enabled()
    }

    if (status === 404) {
        if(window.location.pathname !== '/error-404') {
            router.push({ name: 'error-404' })
        }
    }

    if (status === 401) {
        AuthProvider.removeToken()
        AuthProvider.removeRefreshToken()
        //
        if(window.location.pathname !== authConfig.loginUri) {
            router.push({ name: 'auth.login' })
        }
    }

    // const errorPages = store.getters.errorPages

    return Promise.reject(error)
})

export default request
