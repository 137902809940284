import {authLogin, authLogout, authUser} from '@/api/auth'
import AuthProvider from '@/tools/AuthProvider'
import appsBootstrap from '@/tools/bootstrap/appsBootstrap'

export default {
    namespaced: true,
    state: {
        user: null,
        memberType: null
    },
    getters: {
        user: state => state.user,
        memberType: state => state.memberType
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = Object.assign({}, payload)
        },
        SET_MEMBER_TYPE(state, payload) {
            state.memberType = payload
        }
    },
    actions: {
        // user login
        async login({ commit, dispatch }, payload) {
            try {
                const { email, password } = payload
                const response = await authLogin({
                    email,
                    password
                })

                const { accessToken, refreshToken } = response.data.data

                AuthProvider.setToken(accessToken)
                AuthProvider.setRefreshToken(refreshToken)

                await dispatch('fetchAuthUser')

                return Promise.resolve(response)
            } catch (error) {
                return Promise.reject(error)
            }
        },

        async logout({ commit, dispatch }) {
            try {
                await authLogout()

                AuthProvider.removeToken()
                AuthProvider.removeRefreshToken()
            } catch (error) {
                return Promise.reject(error)
            }
        },

        async fetchAuthUser({ commit, dispatch }) {
            try {
                const response = await authUser()
                commit('SET_USER', response.data.data)
                commit('SET_MEMBER_TYPE', response.data.data.name)

                await dispatch('app/bootstrap', {}, {root: true})
                await appsBootstrap.boot()

                return Promise.resolve(response)
            } catch (error) {
                return Promise.reject(error)
            }
        }
    }
}
