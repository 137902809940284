export default {
    USERS: 'Користувачі',
    HINT: 'Управління адміністраторами',
    NAME: 'Нік',
    EMAIL: 'Email',
    PASSWORD: 'Пароль',
    PASSWORD_CONFIRMATION: 'Підтвердження пароля',
    CREATE_USER: 'Створити користувача',
    UPDATE_USER: 'Оновити профайл',
    DELETE_USER: 'Видалити користувача',
    DELETE_CONFIRM: 'Ви дійсно хочете видалити користувача<br><b>{user}</b>?'
}
