<template>
    <v-navigation-drawer
        v-model="isDrawer"
        :right="!$vuetify.rtl"
        touchless
        app
        temporary
        :width="width"
        class="w-navigation-drawer"
    >
        <v-card
            :disabled="loading"
            tile
            class="w-navigation-drawer__container"
        >
            <v-card-title
                class="drawer-header d-flex align-center text-truncate py-2 px-4"
            >
                <div
                    v-if="isTitle"
                    class="text-truncate text--primary"
                    style="max-width: 90%"
                >
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="loading"
                    icon
                    @click="$emit('input', false)"
                >
                    <w-icon value="CLOSE"/>
                </v-btn>
            </v-card-title>
            <v-progress-linear
                :active="loading"
                height="2"
                indeterminate
                absolute
                color="primary"
            />
            <v-divider></v-divider>
            <v-card-text
                class="w-navigation-drawer__content py-4"
            >
                <slot></slot>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions></v-card-actions>
        </v-card>
    </v-navigation-drawer>
</template>

<script>

export default {
    name: 'WAsideDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: undefined
        },
        width: {
            type: [ String, Number ],
            default: 420
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        isTitle() {
            return this.title !== undefined || this.$slots.title
        }
    },
    watch: {
        value(flag) {
            this.isDrawer = flag

            if (flag) {
                document.body.classList.add(this.bodyClassName)
            } else {
                document.body.classList.remove(this.bodyClassName)
            }
        },
        isDrawer(flag) {
            this.$emit('input', flag)

            if (flag) {
                document.body.classList.add(this.bodyClassName)
            } else {
                document.body.classList.remove(this.bodyClassName)
            }
        }
    },
    data() {
        return {
            isDrawer: false,
            bodyClassName: 'aside-drawer-is-open'
        }
    },
    mounted() {
        this.$emit('input', this.isDrawer)
        window.addEventListener('keydown', this.keyDownHandler)
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyDownHandler)
    },
    methods: {
        keyDownHandler(event) {
            if (event.code === 'Escape') {
                this.isDrawer = false
            }
        }
    }
}
</script>

<style lang="scss">
    body.aside-drawer-is-open {
        max-height: 100vh;
        overflow: hidden;
    }
    .w-navigation-drawer {
        .drawer-header {
            font-size: 1rem;
            font-weight: 600;
            background-color: #f5f5f5;
        }

        &__container {
            height: 100%;
            overflow: hidden;
        }

        &__content {
            height: calc(100% - 44px - 24px - 2px);
            padding-bottom: 10px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
</style>
