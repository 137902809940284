<template>
    <v-btn
        v-bind="btnPropsComputed"
        @click="$emit('click')"
    >
        <w-icon
            v-if="icon"
            :value="icon"
            :left="isText"
        />
        <span v-if="isText">{{ text }}</span>
    </v-btn>
</template>

<script>
import mbCheck from '@/mixins/breakpointChecker'
import wBtnActionMixin from '@/widgets/components/WButtons/mixins/wBtnActionMixin'

export default {
    name: 'WBtnAdd',
    mixins: [ mbCheck, wBtnActionMixin ]

}
</script>
