// Register apps event listeners
const appsEventListenersFiles = require.context('@/app', true, /listeners.js$/)

const appsEventListenersCollection = appsEventListenersFiles.keys()
    .reduce((navs, path) => {
        const value = appsEventListenersFiles(path)
        const collection = value.default

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < collection.length; i++) {
            const item = collection[i]

            navs.push(item)
        }

        return navs
    }, [])

for (let i = 0; i < appsEventListenersCollection.length; i++) {
    const watcher = appsEventListenersCollection[i]

    if(!watcher.event || !watcher.listener) {
        console.warn('Some Apps Event Listener has not required parameters [event or listener]')

        continue
    }

    if(typeof watcher.event !== 'string') {
        console.warn('Some Apps Event Listener [event] must be string')

        continue
    }

    if(typeof watcher.listener !== 'function') {
        console.warn('Some Apps Event Listener [listener] must be function')

        continue
    }

    window.addEventListener(watcher.event, async e => {
        await watcher.listener(e.detail)
    })
}
