// Register apps routes
const routesFiles = require.context('@/app', true, /router$/)

export default routesFiles.keys().reduce((routes, path) => {

    const value = routesFiles(path)
    const collection = value.default
    // const auth = new AuthProvider()

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < collection.length; i++) {
        const item = collection[ i ]

        routes.push(item)
    }

    return routes
}, [])
