export default {
    SITES: 'Сайти',
    STORAGE: 'Сховище',
    NODE_TYPES: 'Розділи',
    STORAGE_USAGE: 'Використання дискового простору',
    STORAGE_TRASH: 'Звільнення дискового простору',
    STORAGE_USAGE_HINT: 'Дані оновлюються тільки за запитом. Для поновлення статистики натисніть на кнопку Оновити. Після сканування дані про дисковий простір будуть оновлені.',
    SUMMARY_CHART: {
        TITLE: 'Сховище',
        OCCUPIED: 'зайнято',
        USAGE_OF: '{usage} із {total}',
    },
    TRASH_CHART: {
        TITLE: 'Звільніть місце у сховищі',
        OCCUPIED: 'Можна звільнити'
    },
    SUMMARY_TABLE: {
        USING: 'Використовується',
        TRASH: 'Можна звільнити',
        THUMBNAIL: 'Мініатюрки',
        ERRORS: 'Помилок сканування',
    },
    SCANNING_CONFIRMATION: 'Бажаєте оновити повторно?',
    SCANNING_PROCESS: 'Виконується сканування, дочекайтесь завершення операції',
    SCANNING_PROCESS_OUTSIDE: 'Наразі виконується сканування на сайті<br><b>{site}</b>,<br>дочекайтесь завершення операції',
    LAST_UPDATED: 'Останнє оновлення',
    NEVER: 'Ніколи',
    UPDATING: 'Оновлення',
    SCANNED: 'Проскановано',
    OF_FILES: 'файлів',
    OF_SITES: 'сайтів',
    DATA_INTEGRITY_ERROR: 'УПС! Порушена цілісність даних. Деякі файли можуть бути недоступні',
    FIELD: 'Поле',
    FILE: 'Файл',
    FILES: 'Файли',
    CONTENT: 'Контент',
    TYPE: 'Тип',
    SIZE: 'Розмір',
    UPDATED: 'Оновлено',
    DELETING_PROCESS: 'Видалення файлів',
    DELETE_FILE_CONFIRMATION: 'Ви дійсно бажаєте видалити файл?<br><b><small>{file}</small></b><br><br><span class="error--text">Майте на увазі!<br>Цю дію неможна відмінити в майбутньому!</span>',
    DELETE_FILES_CONFIRMATION: 'Ви дійсно бажаєте видалити вибрані файли <b>({count})</b>?<br><br><span class="error--text">Майте на увазі!<br>Цю дію неможна відмінити в майбутньому!</span>',
    DELETE_THUMBNAILS_CONFIRMATION: 'Ви дійсно бажаєте видалити усі мініатюрки?<br><br><span class="error--text">Майте на увазі!<br>Цю дію неможна відмінити в майбутньому!</span>',
    THUMBNAILS_HELP: 'Мініатюрки - це зменшені копії зображень для більш легкого відображення на сторінках сайту! Мініатюрки створюються автоматично при запросі до файлу!',
    EXPORT_TO_EXEL: 'Експорт XLSX',
    EXPORTING_PROCESS: 'Експорт даних, дочекайтесь завершення операції',
    DOWNLOAD_FILE: 'Завантажити файл',
    DASHBOARD: 'Адмін панель',
    SELECT_ALL_FILES: 'Вибрати усі файли',
    SELECTED_ALL_FILES: 'Вибрано {count} файлів',
    VIEW: 'Перегляд',
    SETTINGS: 'Налаштування',
    FIELDS: 'Поля',
    ARCHIVE_CONTENT: 'Архівація даних',
    ARCHIVE_PROCESS: {
        STEP_1: {
            TITLE: 'Типи контенту',
            SUBTITLE: 'Виберіть тип контенту який потрібно додати в архів'
        },
        STEP_2: {
            TITLE: 'Рік',
            SUBTITLE: 'Вкажіть рік за який потрібно створити архів',
            MOVE: 'Перемістити',
            COPY: 'Копіювати'
        },
        STEP_3: {
            TITLE: 'Перевірка',
            SUBTITLE: 'Перевірка вказаних даних на доступність та коректність',
            COMMON_TYPE: 'Архів (загальне сховище)'
        },
        STEP_4: {
            TITLE: 'Процес',
            SUBTITLE: 'Не закривайте вкладку браузера до завершення'
        },
    }
}
