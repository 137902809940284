import _ from 'lodash'
import axios from 'axios'
import Notification from '@/plugins/notification/Notification'
import Debugger from '@/tools/Debugger'
import localisationResolver from '@/plugins/Trans/localisationResolver'

const request = axios.create()

// response interceptor
request.interceptors.response.use(response => {
    const res = response

    // Debugger.log('plugins/api.success: ', res)

    // Set message response if exist
    const dataMessage = _.get(res, 'data.message', false)
    if (Notification.isEnabled() && dataMessage) {
        const message = localisationResolver(dataMessage)

        Notification.success(message)
    }

    if (Notification.isDisabled()) {
        Notification.enabled()
    }

    return response
}, async (error) => {
    const res = error.response

    Debugger.log('plugins/request.api.error: ', error.response) // For debug

    const status = Number(_.get(res, 'status', 500))

    let message

    if (_.has(res, 'data.error.message')) {
        message = _.get(res, 'data.error.code') || res.data.error.message
        message = localisationResolver(message)
    } else if (_.has(res, 'statusText')) {
        message = res.statusText
    }

    if (status === 401) {
        Notification.disabled()
    }

    if (Notification.isEnabled() && message) {
        Notification.error(message)
    }

    if(Notification.isDisabled()) {
        Notification.enabled()
    }

    return Promise.reject(error)
})

export default request
