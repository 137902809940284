import Vue from 'vue'
import request from '@/tools/request'
import {VSTUP_BASE_API} from '@/app/vstup/tools/constants'

const state = {
    entryPoints: {},
}
const getters = {
    entryPoints: state => state.entryPoints
}

const mutations = {
    SET_ENTRY_POINTS(state, entryPoints) {
        Vue.set(state, 'entryPoints', entryPoints)
    }
}

const actions = {
    async vstupBootstrap({commit}) {
        try {
            const response = await request({
                url: VSTUP_BASE_API + '/bootstrap',
                method: 'GET'
            })

            commit('SET_ENTRY_POINTS', response.data['entry_points'])
        } catch (error) {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
