export default {
    TITLE: 'Клонування розділу',
    SUBTITLE: 'На цій сторінці ви маєте змогу створювати копії розділів між сайтами кафедр',
    SITE: 'Сайт',
    TYPE: 'Тип контенту',
    NODE_TYPE: 'Розділ',
    BLOCK_TYPE: 'Блок',
    STEP_1: {
        TITLE: 'Копіювати з:',
        SUBTITLE: 'Вкажіть сайт кафедри з якого потрібно вибрати розділ',
    },
    STEP_2: {
        TITLE: 'Розділи',
        SUBTITLE: 'Виберіть розділи для копіювання',
        SELECTED: 'Вибрано розділів: {count} '
    },
    STEP_3: {
        TITLE: 'Вставити в:',
        SUBTITLE: 'Вкажіть сайт кафедри куди потрібно скопіювати розділи'
    },
    STEP_4: {
        TITLE: 'Перевірка',
        SUBTITLE: 'Перевірка вказаних даних на доступність та коректність'
    },
    STEP_5: {
        TITLE: 'Процес',
        SUBTITLE: 'Не закривайте вкладку браузера до завершення'
    },

}
