import Vue from 'vue'
import store from '@/store'
import Notification from './Notification'
import notificationStore from './notificationStore'

const NotificationPlugin = {
    install(Vue) {
        store.registerModule('notification', notificationStore)

        Vue.prototype.$notification = Notification
    }
}

Vue.use(NotificationPlugin)
