<template>
    <v-icon
        :color="color"
        :size="size"
    >
        {{ icon }}
    </v-icon>
</template>

<script>
export default {
    name: 'WBooleanMarker',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            default: 'radiobox'
        },
        color: {
            type: String,
            default: 'primary lighten-1'
        },
        size: {
            type: String,
            default: undefined
        }
    },
    computed: {
        icon() {
            const defaultType = 'radiobox'
            const currentType = this.type
            const value = this.value === true ? 'yes' : 'no'
            const type = !Object.keys(this.icons).includes(currentType)
                ? defaultType
                : currentType

            return this.icons[type][value]
        }
    },
    data: () => ({
        icons: {
            radiobox: {
                yes: 'mdi-radiobox-marked',
                no: 'mdi-radiobox-blank'
            }
        }
    })
}
</script>

<style lang=scss>

</style>
