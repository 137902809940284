import request from '@/tools/request'

export default {
    namespaced: true,
    state: {
        notify: undefined,
        snackbar: undefined,
        isDisabled: false
    },
    getters: {
        isDisabled: state => state.isDisabled,
        snackbar: state => !state.isDisabled ? state.snackbar : false,
        notify: state => state.notify
    },
    mutations: {
        SET_NOTIFY(state, payload) {
            state.notify = payload
        },
        SET_SNACKBAR(state, payload) {
            state.snackbar = payload
        },
        SET_DISABLED(state, flag) {
            state.isDisabled = flag
        },
    },
    actions: {
        disabled({ commit }, flag) {
            commit('SET_DISABLED', flag)
        },

        openNotify({ commit }, payload) {
            commit('SET_NOTIFY', payload)
        },

        async closeNotify({
            commit,
            state
        }) {
            if (state.notify.onClose) {
                await request(state.notify.onClose)
            }

            commit('SET_NOTIFY', undefined)
        },

        openSnackbar({ commit }, payload) {
            commit('SET_SNACKBAR', payload)
        },

        async closeSnackbar({
            commit,
            state
        }) {
            if (state.notify.onClose) {
                await request(state.notify.onClose)
            }

            commit('SET_SNACKBAR', undefined)
        }
    }
}
