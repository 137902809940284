import request from '@/tools/request'
import requestApi from '@/tools/request.api'

const prefix = 'sites'
const apiStoragePrefix = 'api/backoffice/storage'
const apiArchivePrefix = 'api/backoffice/archive'

export const sitesBootstrap = () => {
    return request({
        url: `${prefix}/bootstrap`,
        method: 'GET'
    })
}

export const checkSiteStorage = (siteUrl, params) => {
    return requestApi({
        url: `${siteUrl}/${apiStoragePrefix}/check`,
        method: 'GET',
        params
    })
}

export const getSiteStorageSummary = (siteUrl) => {
    return requestApi.get(`${siteUrl}/${apiStoragePrefix}/summary`)
}

export const clearSiteStorageSummary = (siteUrl) => {
    return requestApi.delete(`${siteUrl}/${apiStoragePrefix}/summary`)
}

export const getSiteStorageUsing = (siteUrl) => {
    return requestApi.get(`${siteUrl}/${apiStoragePrefix}/using`)
}

export const getSiteStorageTrash = (siteUrl) => {
    return requestApi.get(`${siteUrl}/${apiStoragePrefix}/trash`)
}

export const deleteSiteStorageTrash = (siteUrl, uri) => {
    return requestApi({
        url: `${siteUrl}/${apiStoragePrefix}/trash`,
        method: 'DELETE',
        params: {
            uri
        }
    })
}

export const deleteSiteStorageThumbnails = (siteUrl) => {
    return requestApi({
        url: `${siteUrl}/${apiStoragePrefix}/thumbnails`,
        method: 'DELETE'
    })
}

export const getSiteStorageCheckErrors = (siteUrl) => {
    return requestApi.get(`${siteUrl}/${apiStoragePrefix}/errors`)
}

export const getStorageSummary = (siteId) => {
    return request({
        url: `${prefix}/storage/${siteId}`,
        method: 'GET'
    })
}

export const setStorageSummary = (data) => {
    return request({
        url: `${prefix}/storage`,
        method: 'POST',
        data
    })
}

export const getNodeTypeCollection = (siteUrl) => {
    return requestApi.get(`${siteUrl}/api/backoffice/node-type`)
}

export const archiveGetNodeTypeCollection = (siteUrl) => {
    return requestApi({
        method: 'get',
        url: `${siteUrl}/${apiArchivePrefix}/node-type-collection`
    })
}

export const archiveDataValidation = (siteUrl, data) => {
    return requestApi({
        method: 'post',
        url: `${siteUrl}/${apiArchivePrefix}/data-validation`,
        data
    })
}

export const archiveCloneNodeType = (siteUrl, data) => {
    return requestApi({
        method: 'post',
        url: `${siteUrl}/${apiArchivePrefix}/clone-type`,
        data
    })
}

export const archiveCloneNode = (siteUrl, data) => {
    return requestApi({
        method: 'post',
        url: `${siteUrl}/${apiArchivePrefix}/clone-node`,
        data
    })
}
