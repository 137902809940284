<template>
    <v-card
        v-bind="wrapperProps"
    >
        <template
            v-if="!isMobile"
        >
            <v-btn
                v-for="link in items"
                :key="link.text"
                v-bind="itemProps"
                :text="!!link.text"
                :icon="!link.text"
                :to="link.to"
                exact
                class="v-btn--link"
            >
                <w-icon v-if="link.icon" :value="link.icon" :left="!!link.text"/>
                <span v-if="link.text">{{ link.text }}</span>
            </v-btn>
        </template>
        <template
            v-else
        >
            <v-btn
                :min-height="height"
                :width="height"
                :min-width="height"
                icon
                id="btn-menu"
                @click="isAsideMenu = !isAsideMenu"
            >
                <v-icon>mdi-menu-open</v-icon>
            </v-btn>
            <w-aside-drawer
                v-model="isAsideMenu"
                :title="title"
            >
                <v-list>
                    <v-list-item-group
                        v-for="(link, index) in items"
                        :key="index"
                    >
                        <v-list-item
                            :to="link.to"
                            exact
                        >
                            <v-list-item-icon>
                                <w-icon
                                    :value="link.icon | linkIcon"
                                    :left="!!link.text"
                                />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ link.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </w-aside-drawer>
        </template>
    </v-card>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/breakpointChecker'

export default {
    name: 'WToolbarMenu',
    mixins: [
        mobileBreakpointChecker
    ],
    props: {
        title: {
            type: String,
            default: null
        },
        height: {
            type: [String, Number],
            default: 48
        },
        horizontal: {
            type: Boolean,
            default: true
        },
        divider: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        underlined: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: undefined
        },
        dark: {
            type: Boolean,
            default: false
        },
        activeBg: {
            type: String,
            default: undefined
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    filters: {
        linkIcon(icon) {
            return icon || 'NEXT'
        }
    },
    computed: {
        wrapperProps() {
            const props = {
                flat: this.flat,
                tile: this.tile,
                color: this.color,
                dark: this.dark,
                outlined: this.outlined,
                height: this.height,
                class: ['d-flex', 'align-center'],
                style: {}
            }

            const classPrefix = this.horizontal
                ? 'w-toolbar-menu-horizontal'
                : 'w-toolbar-menu-vertical'

            props.class.push(classPrefix)

            if (this.underlined) {
                props.class.push(`${classPrefix}--underlined`)
            }

            if (this.outlined) {
                props.class.push(`${classPrefix}--outlined`)
            }

            if (this.divider) {
                props.class.push(`${classPrefix}--divider`)
            }

            props.style['--wtm-active-bg'] = this.activeBg ? this.activeBg : 'transparent'

            return props
        },
        itemProps() {
            return {
                tile: true,
                depressed: true,
                text: true,
                minHeight: this.height,
                small: this.dense
            }
        },
        wrapperStyles() {
            const styles = {
                tile: true,
                depressed: true,
                text: true,
                minHeight: this.height
            }

            return styles
        }
    },
    data() {
        return {
            isAsideMenu: false
        }
    }
}
</script>

<style lang="scss">

.w-toolbar-menu-horizontal {
    $divider: #e4e3e6;

    position: relative;

    .v-btn--active {
        background-color: var(--wtm-active-bg);
        color: var(--v-anchor-base);

        &:before {
            content: none;
        }
    }

    &--divider {
        .v-btn--link {
            border-right: $divider solid 1px;
        }
    }

    &--underlined {
        &:after {
            position: absolute;
            z-index: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            content: "";
            background-color: $divider;
            height: 2px;
        }

        .v-btn--link.v-btn--active {
            &:after {
                position: absolute;
                z-index: 1;
                left: 0;
                bottom: 0;
                width: 100%;
                content: "";
                background-color: var(--v-primary-base);
                height: 2px;
                border-radius: 6px;
            }
        }
    }

    &--outlined {
        border-bottom-width: 3px !important;

        &.w-toolbar-menu-horizontal--underlined {
            &:after {
                content: none;
            }
        }
    }
}
</style>
