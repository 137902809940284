export default {
    Vstup: 'Вступ',
    TITLE: 'Приймальна комісія',
    SUBTITLE: 'На цій сторінці ви маєте змогу створити архів матеріалів за вступний період для сайту приймальної комісії університету',
    STEP_1: {
        TITLE: 'Типи контенту',
        SUBTITLE: 'Виберіть типи контенту які потрібно додати в архів'
    },
    STEP_2: {
        TITLE: 'Рік',
        SUBTITLE: 'Вкажіть рік за який потрібно створити архів',
        PREFIX: 'Всупна кампанія',
    },
    STEP_3: {
        TITLE: 'Перевірка',
        SUBTITLE: 'Перевірка вказаних даних на доступність та коректність',
        COMMON_TYPE: 'Архів (загальне сховище)',
        MAIN_TYPE: 'Всупна кампанія {year}'
    },
    STEP_4: {
        TITLE: 'Процес',
        SUBTITLE: 'Не закривайте вкладку браузера до завершення'
    },

}
