/* eslint-disable */
import request from '@/tools/request'
import authConfig from '@/config/auth.config'

export function authLogin(data) {
    return request({
        url: `${authConfig.loginUri}`,
        method: 'post',
        data
    })
}

export function authUser() {
    return request({
        url: `${authConfig.userUri}`,
        method: 'get'
    })
}

export function authLogout() {
    return request({
        url: `${authConfig.logoutUri}`,
        method: 'post'
    })
}
