import Vue from 'vue'
import localisationResolver from './localisationResolver'
import pluralizationResolver from './pluralizationResolver'

const TransPlugin = {
    install(v) {
        v.prototype.$trans = localisationResolver
        v.prototype.$transChoice = pluralizationResolver
    }
}

Vue.use(TransPlugin)
