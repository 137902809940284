import Vue from 'vue'
import {sitesBootstrap} from '@/app/sites/api/sites.api'

const state = {
    sites: {},
    storage: [],
    loading: false
}
const getters = {
    sites: state => state.sites,
    storage: state => state.storage,
    loading: state => state.loading,
}

const mutations = {
    SET_SITES(state, payload) {
        Vue.set(state, 'sites', payload)
    },
    SET_STORAGE(state, payload) {
        Vue.set(state, 'storage', payload)
    },
    SET_LOADING(state, payload) {
        Vue.set(state, 'loading', payload)
    }
}

const actions = {
    async bootstrap({commit}) {
        try {
            commit('SET_LOADING', true)

            const response = await sitesBootstrap()

            commit('SET_SITES', response.data['sites'])
            commit('SET_STORAGE', response.data['storage'])
            commit('SET_LOADING', false)
        } catch (error) {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
