<template>
    <v-snackbar
        :value="!!snackbar"
        :color="snackbar.color"
        @input="onInput"
    >
        {{ $trans(snackbar.message || '') }}

        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                small
                text
                v-bind="attrs"
                @click="onClose"
            >
                {{ $trans('Close') }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import Debugger from '@/tools/Debugger'

export default {
    name: 'NotificationSnackbar',
    data: () => ({
        isSnackbar: false
    }),
    computed: {
        snackbar() {
            return this.$store.getters['notification/snackbar'] || false
        }
    },
    methods: {
        onInput(value) {
            if(!value) {
                this.onClose()
            }
        },
        onClose() {
            if(this.snackbar && this.snackbar.onClose) {
                if(typeof this.snackbar.onClose !== 'function') {
                    Debugger.warn('snackbar.onClose must be function')
                } else {
                    this.snackbar.onClose()
                }
            }

            this.$store.commit('notification/SET_SNACKBAR',null)
        }
    }
}
</script>
