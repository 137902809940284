<template>
    <div class="w-search-field">
        <v-chip
            v-if="isResult"
            close
            @click:close="closeResult"
        >
            {{ searchQuery}}
        </v-chip>
        <v-btn
            v-else
            color="primary"
            icon
            @click="openDialog"
        >
            <v-icon>{{ searchAppendIcon }}</v-icon>
        </v-btn>
        <v-dialog
            v-model="isDialog"
            max-width="500"
            @keydown.esc="closeDialog"
            @click:outside="closeDialog"
        >
            <v-card>
                <v-card-text class="pb-6 pt-2">
                    <v-text-field
                        v-model="searchQuery"
                        :placeholder="placeholder"
                        single-line
                        clearable
                        hide-details
                        autofocus
                        :append-icon="searchAppendIcon"
                        @click:append="onSearch"
                        @click:clear="onSearch"
                        @keydown.enter="onSearch"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'WSearchField',
    computed: {
        placeholder() {
            return this.$trans('Search')
        }
    },
    data() {
        return {
            isDialog: false,
            isResult: false,
            searchQuery: null,
            searchAppendIcon: 'mdi-magnify'
        }
    },
    methods: {
        openDialog() {
            this.isDialog = true
            this.searchQuery = null
        },
        closeDialog() {
            this.isDialog = false
        },
        closeResult() {
            this.isResult = false
            this.searchQuery = null
            this.$emit('input', null)
        },
        onSearch() {
            this.isDialog = false

            if(this.searchQuery) {
                this.isResult = true
                this.$emit('input', this.searchQuery)
            }
        }
    }
}
</script>

<style lang=scss>

</style>
