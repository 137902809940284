import _ from 'lodash'
import store from '@/store'

export default (key, replace) => {
    if (!!key && typeof key !== 'string') {
        throw new Error(key + ' must be string. ' + typeof key + ' given')
    }

    if (replace && (typeof replace !== 'object' || Array.isArray(replace))) {
        throw new Error(key + ' must be object. ' + typeof replace + ' given')
    }

    let result = _.get(store.state.app.localisation, key, key)

    if (replace) {
        for (const replaceKey in replace) {
            result = result.replace(new RegExp(`{${replaceKey}}`), replace[replaceKey])
        }
    }

    return result
}
