import _ from 'lodash'
import { getLocales } from '@/api/app'
import appsLocalisations from '@/tools/bootstrap/appsLocalisations'
import commonLocales from '@/locales'

export default {
    namespaced: true,
    state: {
        title: null,
        subtitle: null,
        comeback: null,
        actions: null,
        toolbarMenu: null,
        locale: 'uk',
        localisation: {},
        languages: [],
        enabledLocales: [],
        firstDayOfWeek: 1,
        isAsideMenu: null
    },
    getters: {
        title: state => state.title,
        subtitle: state => state.subtitle,
        comeback: state => state.comeback,
        actions: state => state.actions,
        toolbarMenu: state => state.toolbarMenu,
        locale: state => state.locale,
        languages: state => state.languages,
        localisation: state => state.localisation,
        enabledLocales: state => state.enabledLocales,
        firstDayOfWeek: state => state.firstDayOfWeek,
        isAsideMenu: state => state.isAsideMenu
    },
    mutations: {
        SET_TITLE(state, title) {
            state.title = title

            if(title) {
                document.title = title
            }
        },
        SET_SUBTITLE(state, subtitle) {
            state.subtitle = subtitle
        },
        SET_COMEBACK(state, comeback) {
            state.comeback = comeback
        },
        SET_ACTIONS(state, actions) {
            state.actions = !!actions && actions.length > 0 ? actions : null
        },
        SET_TOOLBAR_MENU(state, toolbarMenu) {
            state.toolbarMenu = !!toolbarMenu && toolbarMenu.length > 0 ? toolbarMenu : null
        },
        SET_LOCALISATION(state, serverLocalisation) {
            state.localisation = _.merge(commonLocales[state.locale], appsLocalisations(state.locale), serverLocalisation)
        },
        SET_ASIDE_MENU_STATE(state, flag) {
            state.isAsideMenu = flag
        }
    },
    actions: {
        setTitle({commit}, title) {
            commit('SET_TITLE', title)
        },
        async bootstrap({commit, state}) {
            try {
                const response = await getLocales(state.locale)
                commit('SET_LOCALISATION', response.data.data)
            } finally {
                //
            }
        }
    }
}
