import Cookies from 'js-cookie'
import authConfig from '@/config/auth.config'
import store from '@/store'
import { getUser } from '@/api/users'

const user = () => {
    return store.getters['auth/user']
}

const getToken = () => {
    return Cookies.get(authConfig.tokenKey)
}

const setToken = (accessToken) => {
    Cookies.set(authConfig.tokenKey, accessToken)
}

const removeToken = () => {
    return Cookies.remove(authConfig.tokenKey)
}

const getRefreshToken = () => {
    return localStorage.getItem(authConfig.refreshTokenKey)
}

const setRefreshToken = (refreshToken) => {
    localStorage.setItem(authConfig.refreshTokenKey, refreshToken)
}

const removeRefreshToken = () => {
    localStorage.removeItem(authConfig.refreshTokenKey)
}

const fetchAuthUser = () => {
    return store.dispatch('auth/fetchAuthUser')
}

const memberType = () => {
    return store.state.auth.memberType
}


/**
 *
 * @param {Array|null} members
 * @param {Array|null} roles
 * @return Boolean
 */
const hasAccess = ({ members, roles }) => {

    const isMembersAvailable = members && Array.isArray(members)
    const isRolesAvailable = roles && Array.isArray(roles)
    const authRoles = roles || []

    if (!isMembersAvailable && !isRolesAvailable) {
        return false
    }

    const isMember = isMembersAvailable && members.includes(memberType())
    const hasRole = isRolesAvailable && roles.filter(o => authRoles.includes(o)).length > 0

    // if (isMembersAvailable && isRolesAvailable) {
    //     return isMember && hasRole
    // } else if (isMembersAvailable && !isRolesAvailable) {
    //     return isMember
    // } else if (!isMembersAvailable && isRolesAvailable) {
    //     return hasRole
    // }

    return isMember || hasRole
}

const routeGuard = async (to, from, next) => {
    const isToken = !!getToken()
    const loginUri = authConfig.loginUri
    const whiteList = ['auth.login'] // no redirect whitelist
    const isWhiteList = whiteList.includes(to.name)

    // determine whether the user has logged in
    const nextPoint = isWhiteList ? {name: 'home'} : undefined

    if (isToken) {
        if (!user()) {
            try {
                await fetchAuthUser()

                next(nextPoint)
            } catch (err) {
                next(loginUri)
            }
        } else {
            next(nextPoint)
        }
    } else {
        if(isWhiteList) {
            next()
        } else {
            next(loginUri)
        }
    }
}

export default Object.freeze({
    user,
    getToken,
    setToken,
    removeToken,
    getRefreshToken,
    setRefreshToken,
    removeRefreshToken,
    routeGuard,
    hasAccess
})
