<template>
    <v-dialog
        :value="value"
        :width="width"
        :persistent="persistent"
        :scrollable="scrollable"
        @input="onInput(false)"
        @keydown.esc="!persistent ? onInput(false) : null"
        @click:outside="!persistent ? onInput(false) : null"
    >
        <v-card
            :loading="loading"
            tile
            :disabled="disabled || loading"
            class="w-dialog-drawer"
        >
            <v-card-title
                class="drawer-header d-flex align-center"
            >
                <div v-if="icon">
                    <w-icon left :value="icon"/>
                </div>
                <div
                    v-if="isTitle"
                    class="font-weight-semibold text-base text--primary text-truncate"
                >
                    {{ title }}
                </div>
                <v-spacer></v-spacer>
                <div>
                    <v-btn
                        v-if="!persistent"
                        icon
                        color="primary"
                        @click="onInput(false)"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-4">
                <slot></slot>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'WDialogDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        width: {
            type: [String, Number],
            default: 420
        },
        height: {
            type: [String, Number],
            default: undefined
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isTitle() {
            return this.title !== undefined || this.$slots.title
        }
    },
    data() {
        return {
            isDrawer: false
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style lang=scss>
.w-dialog-drawer {
    .drawer-header {
        background-color: #f5f5f5;
    }
}
</style>
