import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import '@/plugins/vue-toast-notification'
import '@/plugins/LProgress'
import '@/plugins/TextFilters'
import '@/plugins/MomentJs'
import '@/plugins/Trans/TransPlugin'
import '@/plugins/appsEventListeners'
import '@/plugins/notification/plugin'
import '@/plugins/AuthPlugin'
import '@/widgets/WidgetsPlugin'
import '@/styles/styles.scss'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
