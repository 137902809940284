<template>
    <component
        :is="resolveLayoutVariant"
        :style="{background: theme.appBodyColor}"
    >
        <v-progress-linear
            :active="$lprogress.isLoading()"
            height="4"
            indeterminate
            absolute
            color="primary"
        />
        <transition
            :name="appRouteTransition"
            mode="out-in"
            appear
        >
            <v-card
                :disabled="$lprogress.isLoading()"
                tile
                flat
                color="transparent"
            >
                <router-view></router-view>
            </v-card>
        </transition>
        <notification-snackbar/>
    </component>
</template>

<script>

import appConfig from '@/config/app.config'
import NotificationSnackbar from '@/plugins/notification/components/NotificationSnackbar'

export default {
    name: 'App',
    components: {
        NotificationSnackbar,
        'layout-clean': () => import('@/components/Layouts/LayoutClean'),
        'layout-blank': () => import('@/components/Layouts/LayoutBlank')
    },
    data: () => ({
        appRouteTransition: appConfig.app.routeTransition
    }),
    computed: {
        resolveLayoutVariant() {
            if (this.$route.meta.layout === 'blank') return 'layout-blank'

            return 'layout-clean'
        },
        theme() {
            return appConfig.theme
        },
        app() {
            return appConfig.app
        }
    },
    watch: {
        $route(route) {
            if(route.meta.title) {
                document.title = this.$trans(route.meta.title)
            }
        }
    },
    async created() {
        await this.boot()
    },
    methods: {
        async boot() {
            this.$store.commit('app/SET_TITLE', null)
            this.$store.commit('app/SET_SUBTITLE', null)
            this.$store.commit('app/SET_COMEBACK', null)
            this.$store.commit('app/SET_ACTIONS', null)
            this.$store.commit('app/SET_TOOLBAR_MENU', null)
        }
    }
}
</script>
