/* eslint-disable */

import Vue from 'vue'
import store from '@/store'
import moment from 'moment'

const DateFormat = {
    install(vue) {
        vue.filter('dateFormat', function (date, format) {
            format = !format ? 'YYYY-MM-DD HH:mm' : format
            moment.updateLocale(store.getters.locale, {})
            return moment(date).format(format)
        })

        vue.filter('dateFromNow', function (date, withoutSuffix) {
            if(!date) return null

            withoutSuffix = !!withoutSuffix || false

            moment.updateLocale(store.getters.locale, {})
            return moment(date).fromNow(withoutSuffix)
        })

        vue.filter('dateToNow', function (date, withoutSuffix) {
            if(!date) return null

            withoutSuffix = !!withoutSuffix || false

            moment.updateLocale(store.getters.locale, {})
            return moment(date).toNow(withoutSuffix)
        })

        Vue.prototype.$moment = moment
    }
}

Vue.use(DateFormat)
